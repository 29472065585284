













































import { Component, Vue } from 'vue-property-decorator';

@Component({
})
export default class extends Vue {
  message = '404页面不存在';
  homeClickHandler(): void{
    this.$router.push('/index');
  }
}
